import { Builder, builder, BuilderComponent } from '@builder.io/react'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import DefaultErrorPage from 'next/error'
import Head from 'next/head'
import { useRouter } from 'next/router'
// Lazyload these if you we ever actually need them https://github.com/BuilderIO/builder/tree/main/packages/widgets
//import '@builder.io/widgets'
import getBuilderPageContent from '../src/common/queries/getBuilderPageContent'
import getBuilderPromoBanners from '../src/common/queries/getBuilderPromoBanners'
import { getBuilderBannersOrPromos } from '@/src/common/queries/getBuilderBannersOrPromos'
import '@/src/builderCustomComponentImports'

export async function getStaticProps({
    params,
}: GetStaticPropsContext<{ page: string[] }>) {
    const { content } = await getBuilderPageContent({
        params,
    })
    const { corporateBanner } = await getBuilderPromoBanners()
    const {
        corporateData: corporatePromoBanner,
        consumerData: consumerBanner,
    } = await getBuilderBannersOrPromos(true)

    return {
        props: {
            content,
            consumerBanner,
            corporateBanner,
            corporatePromoBanner,
            showHeader: true,
        },
        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every 5 seconds
        revalidate: true,
        notFound: !content,
    }
}

export async function getStaticPaths() {
    // get first 100.  If it pulls exactly 100, then we
    // will start loop to get more pages of 100.  A return of
    // less than 100 means we have reached the end of the pages
    const limit = 100
    let offset = 0
    const pages = await builder.getAll('page', {
        options: {
            noTargeting: true,
            offset,
            limit,
        },
        omit: 'data.blocks',
        limit,
    })
    while (pages.length === limit + offset) {
        offset += pages.length
        const thesePages = await builder.getAll('page', {
            options: {
                noTargeting: true,
                offset,
                limit,
            },
            omit: 'data.blocks',
            limit,
        })
        pages.push(...thesePages)
    }

    const excludeUrlRegexes = [
        /\/virtual-visa-gift-cards$/,
        /\/personalized-visa-gift-card$/,
        /\/predesigned-visa-gift-card$/,
        /^\/corporate\/brands$/,
        /^\/brands/,
        /^\/visa-gift-card-box$/,
        /^\/redeem\/virtual-card$/,
        /^\/perfectgift-plus\/build$/,
        /^\/test\/refer-a-friend$/,
        /^\/login\/refer-a-friend$/,
        /^\/promotions$/,
        /^\/blog$/,
        /^\/gift-guides$/,
    ]

    // Filter out the pages where we have our own page template
    const filteredPages = pages.filter((page) => {
        for (const regex of excludeUrlRegexes) {
            if (page?.data?.url.match(regex)) {
                return false
            }
        }

        return true
    })

    return {
        paths: filteredPages.map((page) => `${page.data?.url}`),
        fallback: 'blocking',
    }
}

export default function Page({
    content,
}: InferGetStaticPropsType<typeof getStaticProps>) {
    const router = useRouter()
    if (router.isFallback) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '75vh',
                }}
            >
                <h1>Loading...</h1>
            </div>
        )
    }

    const isLive = !Builder.isEditing && !Builder.isPreviewing
    if (!content && isLive) {
        return (
            <>
                <Head>
                    <meta name="robots" content="noindex" />
                    <meta name="title" />
                </Head>
                <DefaultErrorPage statusCode={404} />
            </>
        )
    }

    return (
        <>
            <BuilderComponent model="page" content={content} />
        </>
    )
}
